// Generated by Framer (6f17e57)

import { addFonts, addPropertyControls, ControlType, cx, CycleVariantState, Link, RichText, useActiveVariantCallback, useVariantState, withCSS } from "framer";
import { LayoutGroup, motion } from "framer-motion";
import * as React from "react";

const enabledGestures = {Mg2t4drq6: {hover: true, pressed: true}};

const cycleOrder = ["Mg2t4drq6"];

const variantClassNames = {Mg2t4drq6: "framer-v-f3vaz2"};

function addPropertyOverrides(overrides, ...variants) {
const nextOverrides = {}
variants?.forEach(variant => variant && Object.assign(nextOverrides, overrides[variant]))
return nextOverrides
}

const humanReadableVariantMap = {};

const transitions = {default: {damping: 60, delay: 0, duration: 0.3, ease: [0.44, 0, 0.56, 1], mass: 1, stiffness: 500, type: "spring"}};

export interface Props extends React.HTMLAttributes<HTMLDivElement> { layoutId?: string | null; width?: number; height?: number; layoutDependency?: string | number; variant?: keyof typeof humanReadableVariantMap; title?: string; link?: string; tap?: any }

const Component = React.forwardRef<HTMLDivElement, Props>(function({ id, style, className, width, height, layoutId, variant: outerVariant = "Mg2t4drq6", title: Qep2Av165 = "My Account", link: gjIDtqEwr, tap: A1dZD8Jtz, ...restProps }, ref) {

const outerVariantId = humanReadableVariantMap[outerVariant];

const variant = outerVariantId || outerVariant;

const {baseVariant, classNames, gestureVariant, setGestureState, setVariant, transition, variants} = useVariantState({cycleOrder, defaultVariant: "Mg2t4drq6", enabledGestures, transitions, variant, variantClassNames})
const layoutDependency = variants.join("-") + restProps.layoutDependency;

const { activeVariantCallback, delay } = useActiveVariantCallback(baseVariant)

const onTap1k9osrd = activeVariantCallback(async (...args) => {
if (A1dZD8Jtz) {
const res = await A1dZD8Jtz(...args);
if (res === false) return false;
}
})

const defaultLayoutId = React.useId()

return (<LayoutGroup id={layoutId ?? defaultLayoutId}>
<motion.div initial={variant} animate={variants} onHoverStart={() => setGestureState({isHovered: true})} onHoverEnd={() => setGestureState({isHovered: false})} onTapStart={() => setGestureState({isPressed: true})} onTap={() => setGestureState({isPressed: false})} onTapCancel={() => setGestureState({isPressed: false})} className={cx("framer-4Ddph", classNames)} style={{display: "contents"}}>
<Link href={gjIDtqEwr}><motion.a {...restProps} className={`${cx("framer-f3vaz2", className)} framer-9bh74v`} data-framer-name={"Button"} data-highlight layoutDependency={layoutDependency} layoutId={"Mg2t4drq6"} onTap={onTap1k9osrd} ref={ref} style={{backgroundColor: "rgba(140, 198, 62, 0.75)", borderBottomLeftRadius: 100, borderBottomRightRadius: 100, borderTopLeftRadius: 100, borderTopRightRadius: 100, ...style}} transition={transition} variants={{"Mg2t4drq6-hover": {backgroundColor: "rgba(140, 198, 62, 0.4)"}, "Mg2t4drq6-pressed": {backgroundColor: "rgb(140, 198, 62)"}}} {...addPropertyOverrides({"Mg2t4drq6-hover": {"data-framer-name": undefined}, "Mg2t4drq6-pressed": {"data-framer-name": undefined}}, baseVariant, gestureVariant)}><RichText __fromCanvasComponent children={<React.Fragment><motion.p style={{"--font-selector": "R0Y7UG9wcGlucy02MDA=", "--framer-font-family": "\"Poppins\", sans-serif", "--framer-font-size": "18px", "--framer-font-weight": "600", "--framer-line-height": "28px", "--framer-text-alignment": "center", "--framer-text-color": "var(--extracted-r6o4lv)"}}>My Account</motion.p></React.Fragment>} className={"framer-8oy1i9"} data-framer-name={"My Account"} fonts={["GF;Poppins-600"]} layoutDependency={layoutDependency} layoutId={"kcPe0gvTy"} style={{"--extracted-r6o4lv": "rgb(255, 255, 255)", "--framer-paragraph-spacing": "0px"}} text={Qep2Av165} transition={transition} verticalAlignment={"top"} withExternalLayout/></motion.a></Link>
</motion.div>
</LayoutGroup>)

});

const css = [".framer-4Ddph [data-border=\"true\"]::after { content: \"\"; border-width: var(--border-top-width, 0) var(--border-right-width, 0) var(--border-bottom-width, 0) var(--border-left-width, 0); border-color: var(--border-color, none); border-style: var(--border-style, none); width: 100%; height: 100%; position: absolute; box-sizing: border-box; left: 0; top: 0; border-radius: inherit; pointer-events: none; }", "@supports (aspect-ratio: 1) { body { --framer-aspect-ratio-supported: auto; } }", ".framer-4Ddph .framer-9bh74v { display: block; }", ".framer-4Ddph .framer-f3vaz2 { align-content: center; align-items: center; cursor: pointer; display: flex; flex-direction: row; flex-wrap: nowrap; gap: 10px; height: min-content; justify-content: center; overflow: hidden; padding: 12px 24px 12px 24px; position: relative; text-decoration: none; width: min-content; will-change: transform; }", ".framer-4Ddph .framer-8oy1i9 { flex: none; height: auto; position: relative; white-space: pre; width: auto; }", ".framer-4Ddph .framer-v-f3vaz2 .framer-f3vaz2 { cursor: pointer; }", "@supports (background: -webkit-named-image(i)) and (not (font-palette:dark)) { .framer-4Ddph .framer-f3vaz2 { gap: 0px; } .framer-4Ddph .framer-f3vaz2 > * { margin: 0px; margin-left: calc(10px / 2); margin-right: calc(10px / 2); } .framer-4Ddph .framer-f3vaz2 > :first-child { margin-left: 0px; } .framer-4Ddph .framer-f3vaz2 > :last-child { margin-right: 0px; } }"]

/**
 * This is a generated Framer component.
 * @framerIntrinsicHeight 52
 * @framerIntrinsicWidth 156
 * @framerCanvasComponentVariantDetails {"propertyName":"variant","data":{"default":{"layout":["auto","auto"]},"lVAv6k8L9":{"layout":["auto","auto"]},"sFuZLJwFz":{"layout":["auto","auto"]}}}
 * @framerVariables {"Qep2Av165":"title","gjIDtqEwr":"link","A1dZD8Jtz":"tap"}
 */
const Framervi6hZgnfw: React.ComponentType<Props> = withCSS(Component, css, "framer-4Ddph") as typeof Component;
export default Framervi6hZgnfw;

Framervi6hZgnfw.displayName = "Header – Button";

Framervi6hZgnfw.defaultProps = {height: 52, width: 156};

addPropertyControls(Framervi6hZgnfw, {Qep2Av165: {defaultValue: "My Account", displayTextArea: false, title: "Title", type: ControlType.String}, gjIDtqEwr: {title: "Link", type: ControlType.Link}, A1dZD8Jtz: {title: "Tap", type: ControlType.EventHandler}} as any)

addFonts(Framervi6hZgnfw, [{family: "Poppins", moduleAsset: {localModuleIdentifier: "local-module:canvasComponent/vi6hZgnfw:default", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf"}, style: "normal", url: "https://fonts.gstatic.com/s/poppins/v20/pxiByp8kv8JHgFVrLEj6V1tvFP-KUEg.ttf", weight: "600"}])